import React from "react"
import { BlogPostsGrid, Container, HeroBlog, Main, Metadata, Section } from "components"

export default function Blog() {
	return (
		<Main>
			<Metadata
				pageTitle="Our Blog"
				pageDescription="Give our blog posts a read. We write on hot topics that are trending in the industry."
			/>
			<HeroBlog />
			<Container>
				<Section>
					<BlogPostsGrid count={-1} />
				</Section>
			</Container>
		</Main>
	)
}
